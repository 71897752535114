import { Component } from 'vue-property-decorator'
import { inject } from 'booksprout-app'
import { SET_MIMIC_USER, AuthenticatedUser, UserServiceInterface } from 'booksprout-app'
import { UserDto, PermissionService } from 'booksprout'
import { BaseMixin } from '../../../../../apps/app/src/components/mixins/base'
import { QDialog } from 'quasar'

@Component
export default class SelectUserToSwitchDialog extends BaseMixin {
  @inject('UserService')
  private readonly userService!: UserServiceInterface<UserDto>

  users: AuthenticatedUser[] = []
  loading: boolean = true
  searchUser = ''

  get dialog (): QDialog {
    return this.$refs.selectUserToSwitchDialog as QDialog
  }

  show () {
    this.dialog.show()
  }

  hide () {
    this.dialog.hide()
  }

  onDialogHide () {
    this.$emit('hide')
  }

  cancel () {
    this.$emit('ok')
    this.hide()
  }

  onSelectMimicUserClick (user: AuthenticatedUser) {
    this.$store.dispatch(SET_MIMIC_USER, user).then(() => {
      this.navigateToOtherApp('publisher/review-campaigns')
      this.hide()
    })
  }

  onStopMimicClick () {
    void this.$store.dispatch(SET_MIMIC_USER, null)

    if (!this.authenticatedUser.authorRole) {
      this.navigateToOtherApp('reviewer/review-copies')
    }
  }

  doSearch () {
    this.userService.globalLoader(false).getUsersToMimic(this.searchUser).then(users => {
      // Map to an authenticated user so we have permission access.
      this.users = users.map(u => {
        const user: AuthenticatedUser = {
          ...u,
          permissionQuery: new PermissionService().configure(u)
        }
        return user
      })
      this.userService.globalLoader(true)
      this.loading = false
    })
  }

  created () {
    this.doSearch()
  }
}
